import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators

} from '@angular/forms';

import { } from '@angular/forms';
import { LocationService } from 'src/app/service/location/Sales_location.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CatagoyrService } from 'src/app/service/catagory/catagory.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductSubCatagory } from 'src/app/modules/ProductSubCatagory';
import { } from '@angular/core';
import { Post } from 'src/app/modules/Post';

import { PostSpecification } from 'src/app/modules/PostSpecification';
import { Post_status } from 'src/app/modules/Post_status';
import { UserPostService } from 'src/app/service/post/User_post.service';
import { EditPostYesNoDialogComponent } from './edit-post-yes-no-dialog/edit-post-yes-no-dialog.component';
 
import { CurrencyService } from 'src/app/service/currency/currency.service';
import { GlobalConstants } from 'src/app/utility/global-constants';
import { SubcatagoryService } from 'src/app/service/subcatagory/subcatagory.service';
import { ProductCatagory } from 'src/app/modules/ProductCatagory';
import { SpecificationHead } from 'src/app/modules/SpecificationHead';
import { PostSave } from 'src/app/modules/post/PostSave';
import { TokenStorageService } from 'src/app/service/tokenStorage/token-storage.service';
import { PostPayment } from 'src/app/modules/PostPayment';
import { PostSpecificationSummary } from 'src/app/modules/post/PostSpecificationSummary';
import { CommonService } from 'src/app/service/common/common.service';
import { DataUrl, NgxImageCompressService, UploadResponse } from 'ngx-image-compress';
 
import { PictureUpload } from 'src/app/modules/PictureUpload';
import { MessageDialogSerice } from 'src/app/service/messageDialog/MessageDialog.service';
import { LoadingService } from 'src/app/service/LodingService/loding-service.service';
import { Utility } from 'src/app/utility/Utility';
 


const AUTH_PATH = GlobalConstants.serverUrl

@Component({
  selector: 'app-editpost',
  templateUrl: './editpost.component.html',
  styleUrls: ['./editpost.component.css']
})
export class EditpostComponent implements OnInit {
  form: FormGroup;
  payLoad = '';

  // old code
  @Input() chooseLabel = 'Add Image';
  @Input() deleteButtonLabel;
  @Input() deleteButtonIcon = 'close';



  imageSrc = [];
  inputFileName: string;

  @Input() files: File[] = [];
  images: String[] = [];

  public catagorys: ProductCatagory[];
  public salesLocations;
  public currency1;
  public selectSubCat: ProductSubCatagory;
  public isSubCatSelected: boolean = false;
  public maximum_number_img: number;
  public form_is_editing = true;
  public image_old = []
  postForm;
  paymentform;
  packageform;
  uploadFileCount: number = 0;
  timeStamp:any
  startPosting
  //
  post: Post
  postsave: PostSave
  pid: number


  postSpecifcation = [];
  //
  price_readonly = true
  price_required = false
  negotiable_readonly = true
  min_readonly = true
  min_required = false
  max_readonly = true
  max_required = false

  //new upload variables
  imgResultAfterCompress: DataUrl[] = [];

  imgResultMultiple: UploadResponse[] = [];
  imgResultCount: number = 0
 
  async submitPost1() {


 
     
    if (this.image_old.length < this.selectSubCat.img_min) {
      this.openSnackBar('upload atleast ' + this.selectSubCat.img_min + ' images', 'Error');
    } else if (this.image_old.length > this.selectSubCat.img_max) {
      this.openSnackBar('more than' + this.selectSubCat.img_min + ' images is not allowed !!!', 'Error');
    } else {


      this.formToObject()  // preppare post
      console.log('Post data For send =>' + JSON.stringify(this.postsave));
      this.loadingService.show()
      const p = await this.postService
        .editPost(this.postsave, this.pid)
        .toPromise();
      if (p) {
        this.loadingService.hide()
           
        console.log("Upload post ads complete ");
        this.router.navigate(['user/post'])
          .then(() => {
             window.location.reload();
          });



            } else {
              this.loadingService.hide()
              console.log("Upload post ads complete ");
              this.router.navigate(['user/post'])
                .then(() => {
                   window.location.reload();
                });
      }
    }
  }
  async uploadFilesOneByOne(index:number) {
    this.loadingService.show()
    this.postService.savePostImages(this.pid, this.imgResultAfterCompress[index]).subscribe(
      r => {
        this.loadingService.hide()
     this.refreshUploadedImageView()
 
      }, e => {
        this.loadingService.hide()
        // this.router.navigate(['user/post'])
        // .then(() => {
        //      
        //   window.location.reload();
        // })
      }
    )
 


  }
   refreshUploadedImageView(){
    this.postService.getAllPostImage(this.pid).subscribe(
      r=>{
        this.image_old.splice(0, this.image_old.length);
        this.image_old=r
        console.log("list of images in post ==>"+JSON.stringify(this.image_old))
      },e=> {
        console.log("list of image  in post error ")
      }
    )
  }

  constructor(
    public dialog: MatDialog,
    private snackbar: MatSnackBar,
    private catServive: CatagoyrService,
    private commonService: CommonService,
    private subcatService: SubcatagoryService,
    private salesLocationService: LocationService,
    private currencyService: CurrencyService,
    private tockenStorage: TokenStorageService,
    private loadingService: LoadingService,
    private postService: UserPostService,
    private router: Router,
    private route: ActivatedRoute,
    private messageDialogService: MessageDialogSerice,
    private imageCompress: NgxImageCompressService,
       ) { }

  ngOnInit() {

    this.route.queryParams.subscribe(
      (result) => {

        this.pid = result.pid;

      },
      (error) => {
        // console.log('pid ==>' + JSON.stringify(error));
      }
    );

    this.postsave = new PostSave(-1, null, "", "", null, null, null, null, null, null,null)


    this.isSubCatSelected = true;





    this.getSourceData();

  }


  async getSourceData() {
    this.loadingService.show()
    const u = await this.commonService.isUserInfoComplete().toPromise();
    if (u != null) {
      this.startPosting = u
      this.loadingService.hide()
    }
     
    const p = await this.postService.getPostByIdOfUserForEdit(this.pid).toPromise();
    if (p != null) {

      this.post = p;
      // console.log("post ==>"+JSON.stringify(this.post))

    } else {
      this.router.navigate(['error']);
    } 
 
    const c = await this.catServive.getallProductCatagory().toPromise();
    if (c != null) this.catagorys = c;
    else console.log('  catagorys retrieve failed!!! ');


    const sc = await this.subcatService.getProductSubCatagoryById(this.post.productSubCatagory.id).toPromise()
    if (sc) this.selectSubCat = sc
    else console.log(' selected subcatagory retrieve failed!!! ');

    const s = await this.salesLocationService.getallSalesLocation().toPromise();
    if (c != null) this.salesLocations = s;
    else console.log('  Sales Location retrieve failed!!! ');

    const cur = await this.currencyService.getallCurrency().toPromise()
    if (cur != null) this.currency1 = cur
    else console.log("  currency retrieve failed!!! ")
    this.image_old = this.post.postImage
    this.objectToForm();
    this.loadingService.hide()
  }



  async uploadMultipleFiles() {
     

    if (this.image_old.length >= this.selectSubCat.img_max) {
      this.messageDialogService.messageDialog("You cannot upload More Than " + this.selectSubCat.img_max + " images", "Error")
      return
    }

    this.imageCompress
      .uploadMultipleFiles()
      .then(async (multipleOrientedFiles: UploadResponse[]) => {
        this.imgResultMultiple = multipleOrientedFiles;
        //==================
        //setting variables
        //===================
        var image_old_count = this.image_old.length  // image count in display
        var required_extra_image_count = this.selectSubCat.img_max - image_old_count // number of images i can add
        var loop_number = 0   //how much i can add

        if (this.imgResultMultiple.length > required_extra_image_count) {  // if i have more than required image
          loop_number = required_extra_image_count
        } else {                      // if i have less image than required
          loop_number = this.imgResultMultiple.length
        }
        //==================
        //setting variables
        //===================
        console.log("image_old_count===>" + image_old_count);
        console.log("required_extra_image_count===>" + required_extra_image_count);
        console.log(" loop ===>" + loop_number);

        for (let index = 0; index < loop_number; index++) {

          console.log(" 02-- i ==>" + index);
          const imgwidth = await this.getImageWidth(this.imgResultMultiple[index].image)
          var size = this.getImageSize(this.imgResultMultiple[index].image)

          if (this.imgResultAfterCompress.length < this.selectSubCat.img_max) { //check number of image 
            if (imgwidth > 800) {

              if (size > 0.5 && size < 5) {
                var ratio = (Number)((0.8 * 100) / size)
                console.log(index + "==  ratio output " + ratio);
                this.imageCompress
                  .compressFile(this.imgResultMultiple[index].image, this.imgResultMultiple[index].orientation, ratio, 80)
                  .then((result: DataUrl) => {
                    console.log(index + "== this index was compressed, width=>" + imgwidth);
                    this.imgResultAfterCompress[index] = result;

                  }).then(() => {
                    this.postService.savePostImages(this.pid, this.imgResultAfterCompress[index]).subscribe(
                      r => {
                    this.refreshUploadedImageView()
                       }, e => {
                        // this.router.navigate(['user/post'])
                        // .then(() => {
                        //      
                        //   window.location.reload();
                        // })
                      }
                    )

                  });
              } else {
                if (size >= 5) {
                  this.messageDialogService.messageDialog("max image size 5MB", "Message");
                }
              } if (size <= 0.5) {
                console.log("Image No need for compression ");

                this.imgResultAfterCompress[index] = this.imgResultMultiple[index].image
                this.uploadFilesOneByOne(index)
              }
            } else {
              this.messageDialogService.messageDialog("min Image width 800px ==>" + imgwidth, "Message");
              // console.log("min Image width 800px ==>" + imgwidth);

            }
          } else {
            this.messageDialogService.messageDialog("Maximum " + this.selectSubCat.img_max + " images are allowed", "Message");
            //console.log("Maximum " + this.selectSubCat.img_max + " images are allowed");

          }



        }


      });




  }
  getImageWidth(file: any) {

    return new Promise<number>((resolve, reject) => {
      const img = new Image();
      img.src = file as string;
      img.onload = () => {
        const height = img.naturalHeight;
        const width = img.naturalWidth;
        resolve(width)
      };

    })



  }
  getImageSize(file: any) {
    var size = this.imageCompress.byteCount(file);
    return (Number)(size / (1024 * 1024))
  }

  // removeFile(event, result: any) {
  //   console.log("removeFile ==>" + JSON.stringify(result))
  //   this.imgResultAfterCompress.splice(result, 1);


  // }
  validate(file: File) {
    for (const f of this.files) {
      if (
        file.size > 3000000
        //f.name === file.name
        //  && f.lastModified === file.lastModified &&
        //  f.size >5000000
        // && ((f.type === 'jpg')||f.type === 'jpg')
      ) {
        return false;
      }
    }
    return true;
  }

  getImageWidths(file) {



    return new Promise<number>((resolve, reject) => {

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          resolve(width)
        };
      };
    })
  }


  isMultiple(): boolean {
    return true;
  }


  get productSubCatagory() {
    return this.postForm.get('productSubCatagory');
  }
  get description() {
    return this.postForm.get('description');
  }
  // get specification() {
  //   return this.postForm.get('specification')
  // }
  get detail() {
    return this.postForm.get('detail');
  }

  get remark() {
    return this.postForm.get('remark');
  }
  // get price() {
  //   return this.postForm.get('price')
  // }
  // get currency() {
  //   return this.postForm.get('currency')
  // }
  get inputFileName1() {
    return this.postForm.get('inputFileName');
  }

  async onCatagoryChange(ev) { // changed


    // console.log( ' on catagory changee = >' + JSON.stringify(ev.value.id));

    if (ev.value.id) {
      const s = await this.subcatService.getProductSubCatagoryById(ev.value.id).toPromise()
      if (s) this.selectSubCat = s


      this.form_is_editing = false; // from now  form is changing based on selection not history
      this.changeCatagory()
    } else {
      this.isSubCatSelected = false;
    }
  }
  async changeCatagory() {


    let group = {};
    this.selectSubCat.specificationList.sort((a, b) => (a.id > b.id) ? 1 : -1)
    this.selectSubCat.specificationList.forEach((specification: SpecificationHead) => {

      group[specification.key] = specification.required ? new FormControl(specification.value || '', Validators.required)
        : new FormControl(specification.value || '');

      /// console.log('specification value' + JSON.stringify(specification.key) );
    });

    this.form = new FormGroup({}); // = this.qcs.toFormGroup(this.questions);

    this.paymentform = new FormGroup({
      payment_option: new FormControl(''),
      negotiable: new FormControl(false),
      price_amount: new FormControl(),
      price_currency: new FormControl(),
      min: new FormControl(''),
      max: new FormControl(''),
      range_currency: new FormControl(''),
    });

    this.postForm = new FormGroup({
      id: new FormControl(''),
      description: new FormControl(''),
      productSubCatagory: new FormControl(''),
      detail: new FormControl(''),
      remark: new FormControl(''),
      inputFileName: new FormControl(''),
      salesLocation: new FormControl(),
      specification: new FormGroup(group),
      post_payment: this.paymentform,
      package_fee: new FormControl("")
    });


    // this.postForm.removeControl('specification')
    // this.postForm.addControl('specification', new FormGroup(group))


    // end of  update form specification list
    this.isSubCatSelected = true; // make catagory slected true
  }

  payment_required() {
    if (this.selectSubCat.commision === false &&
      this.selectSubCat.contact === false &&
      this.selectSubCat.price === false &&
      this.selectSubCat.range === false) {
      return false
    } else return true
  }
  fee_required() {
    if (this.selectSubCat.fee_free_enable === false &&
      this.selectSubCat.fee_week_enable === false &&
      this.selectSubCat.fee_month_enable === false &&
      this.selectSubCat.fee_year_enable === false) {
      return false
    } else return true
  }
  public formToObject() {

    this.postsave.id = this.post.id
    this.postsave.productSubCatagory = this.selectSubCat.id
    this.postsave.description = this.postForm.value.description
    this.postsave.salesLocation = this.postForm.value.salesLocation.id
    this.postsave.detail = this.postForm.value.detail
    this.postsave.remark = this.postForm.value.remark
    this.postsave.post_status = this.getPostStatus()
    this.postsave.user = this.tockenStorage.getUserId()

    // payment option  
    this.postsave.post_payment = this.setPostPayment()

    this.postsave.specificationList = this.getPostSpecification()
    this.postsave.serviceOption = this.getServiceFee()

    return this.postsave;
  }

  paymentOnChange() {
    //  console.log("radio button changed to ==>"+JSON.stringify(this.postForm.value.post_payment))

    if (this.postForm.value.post_payment.payment_option === "PRICE") {
      this.price_readonly = false
      this.price_required = true
      this.negotiable_readonly = false
      this.min_readonly = true
      this.min_required = false
      this.max_readonly = true
      this.max_required = false
      this.postForm.patchValue({
        post_payment: {
          // negotiable:false,
          // price_amount:"",
          min: "",
          max: ""
        }
      })

    } else if (this.postForm.value.post_payment.payment_option === "RANGE") {
      this.postForm.patchValue({
        post_payment: {
          negotiable: false,
          price_amount: "",
          // min:"",
          // max:""
        }
      })

      this.price_readonly = true
      this.price_required = false
      this.negotiable_readonly = true
      this.min_readonly = false
      this.min_required = true
      this.max_readonly = false
      this.max_required = true
    } else {
      this.postForm.patchValue({
        post_payment: {
          negotiable: false,
          price_amount: "",
          min: "",
          max: ""
        }
      })

      this.price_readonly = true
      this.price_required = false
      this.negotiable_readonly = true
      this.min_readonly = true
      this.min_required = false
      this.max_readonly = true
      this.max_required = false
    }
  }

  setPostPayment() {
    var post_payments = new PostPayment(null, "PRICE", true, 0, null, 0, 0, null)
    post_payments.option = this.postForm.value.post_payment.payment_option
    post_payments.price_amount = this.postForm.value.post_payment.price_amount
    post_payments.min = this.postForm.value.post_payment.min
    post_payments.max = this.postForm.value.post_payment.max
    post_payments.negotiable = this.postForm.value.post_payment.negotiable
    post_payments.price_currency = this.postForm.value.post_payment.price_currency
    post_payments.range_currency = this.postForm.value.post_payment.price_currency

    return post_payments
  }

  getPostSpecification() {
    this.postSpecifcation.splice(0, this.postSpecifcation.length)
    //  console.log("length of array =>" + this.selectSubCat.specificationList.length)
    for (let index = 0; index < this.selectSubCat.specificationList.length; index++) {

      // console.log("index = "+index+" data => "+ JSON.stringify(
      // this.postForm.value.specification[this.selectSubCat.specificationList[index].key]
      // ))
      //  console.log("value for send ==> "+
      //  JSON.stringify(this.postForm.value.specification[this.selectSubCat.specificationList[index].key]));


      this.postSpecifcation.push(new PostSpecificationSummary(null,
        this.selectSubCat.specificationList[index].id,
        this.postForm.value.specification[this.selectSubCat.specificationList[index].key]))

    }
    return this.postSpecifcation

  }

  /**
   */
  getPostStatus() {

    return new Post_status(-1, "PENDING", null, null, null, null, null, null, null)
  }


  objectToForm() {
    // console.log("Object to Form")
    //  console.log("post.subcatagory.productSubCatagory==>"+JSON.stringify(this.selec))
    //  console.log("post.subcatagory.specificationList==>"+JSON.stringify(this.post.specificationList))
    //  console.log("ProductSubCatagory size "+this.selec.specificationList.length)
    //  console.log("specificationListsize "+this.post.specificationList.length)
    this.post.specificationList.sort((a, b) => (a.id > b.id) ? 1 : -1)
    this.putSepecifiationListToProductionSubcatagory()
    this.setPostDataToForm()
  }
  getServiceFee() {

    var index = this.postForm.value.package_fee

    console.log("service fee index ==>" + index)


    if (index == 1) {
      return 'FREE'

    }
    else if (index == 2) {
      return 'WEEK'

    }
    else if (index == 3) {
      return 'MONTH'

    }
    else if (index == 4) {
      return 'YEAR'

    }





  }

  /**
   * sets specificationlist value to  ProductSubCAtagory specification list in order to be 
   * used in creating dynamic form  based on the data that was ginven by user  [ upgrade is needed in the code]
   */
  putSepecifiationListToProductionSubcatagory() {
    var i = 0;
    var array_length = this.selectSubCat.specificationList.length //> this.post.specificationList.length ? this.selec.specificationList.length : this.post.specificationList.length

    for (let index = 0; index < array_length; index++) {
      // console.log(" index = "+ index+" -specifiationlist= "+this.post.specificationList[index].specification.id+" product subcatagory = "+
      // this.selec.specificationList[index].id     )
      //
      i = this.selectSubCat.specificationList[index].id

      if (this.selectSubCat.specificationList[index].selectType === "CHILD") {
        var parentkey = this.selectSubCat.specificationList[index].parentkey


        var parentId = (this.post.specificationList.find(x => x.specification.key === parentkey)).id


        this.selectSubCat.specificationList[index].parentvalue =
          (this.post.specificationList.find(x => x.specification.key === parentkey)).value
      }
      var temp = (this.post.specificationList.find(x => x.specification.id === i)).value
      // console.log("temp "+index+"==>"+JSON.stringify(temp));
      if (temp === undefined) {
        this.selectSubCat.specificationList[index].value = "OTHER"
      } else this.selectSubCat.specificationList[index].value = temp



    }
    //console.log(" this.selec.specificationList  8888 ==>" + JSON.stringify(this.selectSubCat))
  }

  setPostDataToForm() {

    this.changeCatagory()

    //  if (!this.form_is_editing) return
    this.postForm.patchValue({
      description: this.post.description,
      detail: this.post.detail,
      remark: this.post.remark,
    })


    var i = 0
    var y = 0
    var z = 0
    var s = 0

        //sales location start

        for (s = 0; s < this.salesLocations.length; s++) {
          if (this.salesLocations[s].id == this.post.salesLocation.id) {
            this.postForm.patchValue({
              salesLocation: this.salesLocations[s]
            })
          }
    
    
        }
    // sales location end

    // catagory change  
    for (i = 0; i < this.catagorys.length; i++) {
      for (y = 0; y < this.catagorys[i].productSubCatagory.length; y++) {

        if (this.catagorys[i].productSubCatagory[y].id === this.selectSubCat.id) {


          this.postForm.patchValue({
            productSubCatagory: this.catagorys[i].productSubCatagory[y]

          })

          break;
        }
      }
    }

    if (this.post.serviceOption != null) {
      if (this.post.serviceOption === "FREE") { this.postForm.patchValue({ package_fee: "1" }) }
      else if (this.post.serviceOption === "WEEK") { this.postForm.patchValue({ package_fee: "2" }) }
      else if (this.post.serviceOption === "MONTH") { this.postForm.patchValue({ package_fee: "3" }) }
      else if (this.post.serviceOption === "YEAR") { this.postForm.patchValue({ package_fee: "4" }) }

    }


    this.paymentform.patchValue({
      payment_option: this.post.post_payment.option,
      negotiable: this.post.post_payment.negotiable,
      price_amount: this.post.post_payment.price_amount,
      min: this.post.post_payment.min,
      max: this.post.post_payment.max,
    })

    // console.log("Payment option ==>" + this.paymentform.value.payment_option)
    this.paymentOnChange() //  reset  required and other staffs


    // payment  price  currency setting
    for (z = 0; z < this.currency1.length; z++) {
      if (this.currency1[z].id === this.post.post_payment.price_currency.id) {
        this.paymentform.patchValue({
          price_currency: this.currency1[z]
        })
      }

    }

    // range price  currency setting
    for (z = 0; z < this.currency1.length; z++) {
      if (this.currency1[z].id === this.post.post_payment.range_currency.id) {
        this.paymentform.patchValue({
          range_currency: this.currency1[z]
        })
      }

    }

    //  service  fee  option



    this.postForm.patchValue({
      post_payment: {
        negotiable: this.post.post_payment.negotiable,
        price_amount: this.post.post_payment.price_amount,
        min: this.post.post_payment.min,
        max: this.post.post_payment.max
      }
    })
  }

  deleteOldImage(name) {

    if (this.deleteDialog(name)) {
      // console.log("success of delit ")
    }

  }
  async deleteDialog(image: PictureUpload) {
    const dialogRef = this.dialog.open(EditPostYesNoDialogComponent, {
      width: '300px',
      data: image,
    });

    dialogRef.afterClosed().subscribe(result => {

      this.deletePostImageAction(result)


    }, error => {

      this.openSnackBar("Image Deleted fail!!!", "Error")
    }

    );
  }

  async deletePostImageAction(image: PictureUpload) {
    const i = await this.postService.deleteImage(this.pid, image.name).toPromise()
    // console.log("i ==>" + JSON.stringify(i))
    if (i) {
      const y = await this.postService.getPostImages(this.pid).toPromise()
      // console.log("y ==>" + JSON.stringify(y))
      if (y) this.image_old = y
      this.openSnackBar("Image Deleted !!!", "Message")
    }
  }


  openSnackBar(message, type) {
    this.snackbar.open(message, type, {
      duration: 2000,
    });
  }
  changeParent(e: any, comp: any) {


    this.selectSubCat.specificationList.forEach((x: SpecificationHead) => {
      if (x.parentkey === comp) {
        x.parentvalue = e
        this.postForm.get("specification").get(x.key).setValue("")
      }

    });


    // console.log("e value ==>" + JSON.stringify(e));
    // console.log("c value ==>" + JSON.stringify(comp));
  }
  getImageUrl(img) {
    
   return Utility.getFileURL( img+"?")//+(new Date()).getTime();
  
  }

  getChildKey(parentkey) {
    this.selectSubCat.specificationList.forEach(element => {
      if (element.parentkey.match(parentkey)) {
        return element.key

      }
    });
  }
}