import { Component, OnInit, Output ,EventEmitter, OnDestroy, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductCatagory } from 'src/app/modules/ProductCatagory';
import { SalesLocation } from 'src/app/modules/SalesLocation';
import { User } from 'src/app/modules/User';
import { CatagoyrService } from 'src/app/service/catagory/catagory.service';
import { LocationService } from 'src/app/service/location/Sales_location.service';
import { TokenStorageService } from 'src/app/service/tokenStorage/token-storage.service';
import { UserService } from 'src/app/service/user/user.service';
import { GlobalConstants } from 'src/app/utility/global-constants';
import { SearchFormComponent } from 'src/app/utility/search-form/search-form.component';
import { Utility } from 'src/app/utility/Utility';
 
const SERVER_URL = GlobalConstants.serverUrl;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit ,OnDestroy {

  @Output() sidenavToggle = new EventEmitter<void>();
   user:User
 
   catagory
   query
   location
   public catagorys:ProductCatagory[];
   public  locationsList:SalesLocation[]
  constructor(private tokenStorage:TokenStorageService,
    private catServive: CatagoyrService,
    private locationService:LocationService,
    private  route:Router,
    public dialog: MatDialog,
    private userService:UserService ) { }

  ngOnInit(): void {
    this.setUserInfo();
  this.getSource()
 
  }
  async  getSource(){

    const c = await this.catServive.getallProductCatagory().toPromise();
    if (c != null){
      this.catagorys = c;
        } 
    const l = await this.locationService.getallSalesLocation().toPromise();
    if (l != null){
      this.locationsList = l;
      
   } 
  }

  async setUserInfo() {
    const s=await   this.userService.getCurrentUser().toPromise();
   
      if(s != undefined) {
        this.user=s
         }

         }

  onToggleSidenav() {
    this.sidenavToggle.emit();
  }

  onLogout() {
     
  }
  getUserImage(){
    if(this.user) {
      
       
      if(this.user.image_name === null) return "assets/default_user.png"
      else {
      return  Utility.getFileURL(this.user.image_name)
      }  
       }else{
         return "assets/default_user.png"
       }
  }

  ngOnDestroy() {
    
  }
  public  userRoleLogedIn(){
  
    return  this.tokenStorage.isRoleAvailable('ROLE_USER')
 }
 public  managerRoleLogedIn(){
  
   return  this.tokenStorage.isRoleAvailable('ROLE_MANAGER')
}
public  adminRoleLogedIn(){
  
  return  this.tokenStorage.isRoleAvailable('ROLE_ADMIN')
}
public  financeRoleLogedIn(){
  
  return  this.tokenStorage.isRoleAvailable('ROLE_FINANCE')
}
public  userLogedIn(){
   return this.tokenStorage.isUserLoggedIn()
}
 
onSearchClick(){
  var c ,l
 
if(this.catagory === undefined  || this.catagory ==="0" ) {
  c=0
}    else c=this.catagory.id
 if(this.location === undefined  ||  this.location === "0"  )  l=0
 else  l=this.location.id
  
this.route.navigate(['/search'],{ queryParams: { query: this.query,loc:l,subcat:c } }).then(() => {
  window.location.reload();
});
}
onMiniSearchClick(){
  const dialogRef = this.dialog.open(SearchFormComponent, {
    width: '350px',
    data: {}
  });

  dialogRef.afterClosed().subscribe(result => {
      
     
  });
  
}


}

   
   
  