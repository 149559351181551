import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/modules/User';
import { UserService } from 'src/app/service/user/user.service';
import { GlobalConstants } from 'src/app/utility/global-constants';
import { Utility } from 'src/app/utility/Utility';

const SERVER_URL = GlobalConstants.serverUrl;
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

 
  userImage:String
  userName:String
  user:User
    constructor(public  userService:UserService) { }
  
    ngOnInit(): void {
      this.setUserInfo()
    }
  
  
    async setUserInfo() {
      const s=await   this.userService.getCurrentUser().toPromise();
 
        if(s != undefined) {
          if(s.image_name=== null) this.userImage = "assets/default_user.png"
          else  Utility.getFileURL(s.image_name)         
           this.userName=s.fullName
        }else{
         
          this.userName=""
          this.userImage = "assets/default_user.png"
         
        }
  
      }

    
  }