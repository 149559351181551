import { PostPayment } from "../modules/PostPayment";
import { GlobalConstants } from "./global-constants";
 
const SERVER_URL = GlobalConstants.serverUrl + "adv/";
export class Utility {



  static getUserImageURL(name: String | undefined) {
    if (name) {


      if (name === null) return "assets/default_user.png"
      else {
        return `${SERVER_URL}img/` + name
      }
    } else {
      return "assets/default_user.png"
    }
  }
  static getFileURL(name: String | undefined) {
    if (name) {


      if (name === null) return "assets/default_user.png"
      else {
        return `${SERVER_URL}img/` + name
      }
    } else {
      return "assets/default_user.png"
    }
  }

  static removeParamFromUrl(url: string) {
     if(url.lastIndexOf('?')>0){
      let url1 = url.substring(0, url.lastIndexOf('?'));
      // console.log("url with out extension"+JSON.stringify(url1));
      
       return url1
     }else  if(url.lastIndexOf('#')>0){
      let url1 = url.substring(0, url.lastIndexOf('#'));
      // console.log("url with out extension"+JSON.stringify(url1));
      
       return url1
     } else  return url

  }
  static displayPrice(post_payment:PostPayment) {
    if (post_payment === undefined) return "";
    if (post_payment.option === 'PRICE') {
      return '' + post_payment.price_amount + " " + post_payment.price_currency.shortName;
    } else if (post_payment.option === 'CONTACT') {
      return 'CONTACT';
    } else if (post_payment.option === 'COMMISSION') {
      return 'COMMISSION';
    } else if (post_payment.option === 'RANGE') {
      return '' + post_payment.min + '-' + post_payment.max + " " + post_payment.range_currency.shortName;
    }
  }
   static tupleToDate(tuple:any): Date {
    const year = tuple[0];
    const month = tuple[1] - 1;  // JavaScript Date months are 0-based (January is 0)
    const day = tuple[2];
    const hour = tuple[3];
    const minute = tuple[4];
    const second = tuple[5];
    const millisecond = tuple[6];
  
    // Create and return a Date object
    return new Date(year, month, day, hour, minute, second, millisecond);
  }
 
}
