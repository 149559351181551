import { Component, OnInit } from '@angular/core';
import { PostService } from 'src/app/service/post/post.service';
import { Post } from 'src/app/modules/Post';
import { MatDialog } from '@angular/material/dialog';
import { UserPostService } from 'src/app/service/post/User_post.service';
import { User } from 'src/app/modules/User';
import { UserService } from 'src/app/service/user/user.service';
import { GlobalConstants } from 'src/app/utility/global-constants';
import { Utility } from 'src/app/utility/Utility';

const SERVER_URL = GlobalConstants.serverUrl;

@Component({
  selector: 'app-privacy-setting',
  templateUrl: './privacy-setting.component.html',
  styleUrls: ['./privacy-setting.component.css']
})
export class PrivacySettingComponent implements OnInit {

  userImage:String
  userName:String
  user:User
    constructor(public  userService:UserService) { }
  
    ngOnInit(): void {
      this.setUserInfo()
    }
  
  
    async setUserInfo() {
      const s=await   this.userService.getCurrentUser().toPromise();
     
        if(s != undefined) {
          if(s.image_name=== null) this.userImage = "assets/default_user.png"
          else  this.userImage=Utility.getFileURL(s.image_name)         
           this.userName=s.fullName
        }else{
         
          this.userName=""
          this.userImage = "assets/default_user.png"
         
        }
  
      }
  
  }
  

