<mat-toolbar color="primary">
    <button mat-icon-button><mat-icon>arrow_back</mat-icon></button>
    <span>Details</span>
    <span class="spacer"></span>
    <button mat-icon-button><mat-icon>block</mat-icon></button>
    <button mat-icon-button><mat-icon>share</mat-icon></button>
    <button mat-icon-button><mat-icon>star_border</mat-icon></button>
  </mat-toolbar>
  
  <div class="image-carousel">
    <mat-carousel>
      <mat-carousel-slide>
        <img src="https://picsum.photos/200/300" alt="Product Image">
      </mat-carousel-slide>
    </mat-carousel>
  </div>
  
  <div class="product-details">
    <h2>Samsung Galaxy S7 Edge (DUL-SIM) 486GB (GOLD) (Used)</h2>
    <p class="date">Posted on 16-09-2022 10:11 am Dhaka, Bangladesh</p>
    <h3 class="price">$500 <span class="negotiable">Negotiable</span></h3>
    <p>For sale by <strong>My User Name</strong></p>
    <p class="verified"><mat-icon color="primary">verified</mat-icon> Verified member</p>
  </div>
  
  <div class="actions">
    <button mat-flat-button color="primary">Chat</button>
    <button mat-flat-button color="accent">Make Offer</button>
  </div>
  
 
  