import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Post } from 'src/app/modules/Post';
import { LoadingService } from 'src/app/service/LodingService/loding-service.service';
import { PostService } from 'src/app/service/post/post.service';
import { TokenStorageService } from 'src/app/service/tokenStorage/token-storage.service';
import { AbuseDialogComponent } from 'src/app/utility/abuse-dialog/abuse-dialog.component';
import { GlobalConstants } from 'src/app/utility/global-constants';
import { ImageViewerDialogComponent } from 'src/app/utility/image-viewer-dialog/image-viewer-dialog.component';
import { LoginDialogComponent } from 'src/app/utility/login-dialog/login-dialog.component';
import { Utility } from 'src/app/utility/Utility';

const AUTH_API = GlobalConstants.serverUrl + 'adv/';

@Component({
  selector: 'app-post-display-page',
  templateUrl: './post-display-page.component.html',
  styleUrls: ['./post-display-page.component.css']
})
export class PostDisplayPageComponent implements OnInit {

  slideIndex = 0
  length: number
  imagePath = ""
  post: Post
  pid: number
  // full screen image property
  showFlag: boolean = false;
  selectedImageIndex: number = -1;
  currentIndex: number = -1
  //
  constructor(private postService: PostService,
    private route: Router,
    public dialog: MatDialog,
    private router: ActivatedRoute,
    private loadingService: LoadingService,
    private tokenStorage: TokenStorageService,
     
  ) {

  }

  async ngOnInit() {
    this.router.queryParams.subscribe(
      result => {
        this.pid = result.pid
        // console.log("post id=>" + this.pid)
      }, error => {
        this.route.navigate(['/error'])
      }

    )
    this.getSource()

  }
  async getSource() {
    this.loadingService.show();

    const p = await this.postService.getPostById(this.pid).toPromise()
    if (p) {
      this.loadingService.hide();
      this.post = p
    

      

   //   console.log("post IMAGE LIST =>" + JSON.stringify(this.getPostImageObect()))
    }
    else this.route.navigate(['/error'])

    // set display setting
    this.length = this.post.postImage.length
    // console.log("post id=>" + JSON.stringify(this.post.postImage.length))

    this.showSlides();
  }



  // Next/previous controls
  preveousSlides() {
    if (this.slideIndex == 0) this.slideIndex = this.length - 1
    else this.slideIndex--
    console.log("previeous  slide ==>" + this.slideIndex)
    this.showSlides();
  }
  nextSlides() {
    console.log("next  slide ==>" + this.slideIndex)
    if (this.slideIndex >= (this.length - 1)) this.slideIndex = 0
    else this.slideIndex++
    this.showSlides();
  }
  getIndex_lenth() {
    return (this.slideIndex + 1) + "/" + (this.length)
  }
  displayPrice() {
    return  Utility.displayPrice(this.post.post_payment);
    // if (this.post === undefined) return "";
    // if (this.post.post_payment.option === 'PRICE') {
    //   return '' + this.post.post_payment.price_amount + " " + this.post.post_payment.price_currency.shortName;
    // } else if (this.post.post_payment.option === 'CONTACT') {
    //   return 'CONTACT';
    // } else if (this.post.post_payment.option === 'COMMISSION') {
    //   return 'COMMISSION';
    // } else if (this.post.post_payment.option === 'RANGE') {
    //   return '' + this.post.post_payment.min + '-' + this.post.post_payment.max + " " + this.post.post_payment.range_currency.shortName;
    // }
  }
  displayNegotiable() {
    if (this.post.post_payment.option === "PRICE")
      return this.post.post_payment.negotiable
    else return false
  }
  showSlides() {
    this.imagePath = Utility.getFileURL( this.post.postImage[this.slideIndex].name)
  }

  getUserImageUrl(img: String) {
    if (img === undefined || img === null) {
      return "assets/default_user.png"
    } else {
      return AUTH_API + "img/" + img
    }

  }

  onAbuseClick() {

    if (this.tokenStorage.isUserLoggedIn())
      this.abuseMessageDialog()

    else
      this.logInDialog()



  }
 

  logInDialog() {
    const dialogRef = this.dialog.open(LoginDialogComponent, {
      maxWidth: "350px"

    });

    dialogRef.afterClosed().subscribe(dialogResult => {
    
      if (dialogResult) this.abuseMessageDialog()
    });

  }
  abuseMessageDialog() {

    // const dialogData = new ConfirmDialogModel(title, message);

    const dialogRef = this.dialog.open(AbuseDialogComponent, {
      maxWidth: "400px",
      data: this.pid
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      dialogResult;
      // if( dialogResult){
      //   this.postService.sendPostToArchive(id).subscribe(
      //     r=>{
      //    this.messageDialog("Message","Post Sent to Archive !!!")
      //    location.reload();
      //     },
      //     e=>{
      //       this.messageDialog("Error","Post Sent to Archive Fail !!!")
      //     }
      //   );
      // }
    });

  }
  // full screen image methods 
  showImageInFullScreen() {
 //   console.log("images to dialog ==>"+JSON.stringify(this.post.postImage))
 const images =this.getPostImageObect().map(obj => obj.image)
      this.dialog.open(ImageViewerDialogComponent, {
      data: { images: images },
      panelClass: 'full-screen-dialog'
    });
 
  }
 
 

  closeEventHandler() {
    this.showFlag = false;
    this.currentIndex = -1;
  }
  getPostImageObect(): any {
    var imageObject: Array<object> = []
    for (let index = 0; index < this.post.postImage.length; index++) {
      imageObject.push({ 'image': AUTH_API + "img/" + this.post.postImage[index].name })

    }
    return imageObject;


  }
  isUserLogIn() {
    return this.tokenStorage.isUserLoggedIn()
  }
  viewPhoneNumber() {
    console.log("View Phone Numer is clicked")
    if (this.tokenStorage.isUserLoggedIn()){
      //  this.abuseMessageDialog()
    }
  else
      this.logInDialog()
  }
}
