import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/service/LodingService/loding-service.service';

@Component({
  selector: 'app-demo1',
  templateUrl: './demo1.component.html',
  styleUrls: ['./demo1.component.css']
})
export class Demo1Component {

  product = {
    title: 'Samsung Galaxy S7 Edge (DUL-SIM) 486GB (GOLD) (Used)',
    date: 'Posted on 16-09-2022 10:11 am Dhaka, Bangladesh',
    price: 500,
    negotiable: true,
    seller: 'My User Name',
    verified: true,
    imageUrl: 'https://picsum.photos/200/300'
  };
}