export class GlobalConstants {

  public static serverUrl: string = "https://d3lfergqw966ev.cloudfront.net/";

  // public static serverUrl: string = "http://localhost:8080/";

  //   public static serverUrl: string = "http://localhost:5000/";

  //  public static serverUrl: string = "https://192.168.10.13:8443/";

  //   public static serverUrl: string = "http://localhost:5000/";

  //     public static serverUrl: string ="https://meazn-backend-ss.us-east-1.elasticbeanstalk.com/"

  //image server url 
  public static ImageServerUrl = "https://s3.us-east-1.amazonaws.com/suqtayi.file.storage/";
  // public static ImageServerUrl = "http://localhost:8080/adv/img/";

  public static website_name = "MEAZN"
  public static location_country = "SOUTH SUDAN"


}