export class GlobalConstants {
       
    public static serverUrl: string = "https://backend.meazn.com/";
  //      public static serverUrl: string = "http://localhost:8080/";
        //   public static serverUrl: string = "http://localhost:5000/";

      //  public static serverUrl: string = "https://192.168.10.13:8443/";
   
  //   public static serverUrl: string = "http://localhost:5000/";
    //     public static serverUrl: string ="https://meazn-backend-ss.us-east-1.elasticbeanstalk.com/"
 

    public static  website_name="MEAZN"
    public static   location_country="SOUTH SUDAN"
    
  
}