import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PostService } from 'src/app/service/post/post.service';
import { UserService } from 'src/app/service/user/user.service';

 
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { Post } from 'src/app/modules/Post';
import { GlobalConstants } from 'src/app/utility/global-constants';
import { TmplAstBlockNode } from '@angular/compiler';
import { TokenStorageService } from 'src/app/service/tokenStorage/token-storage.service';
import { LoginDialogComponent } from 'src/app/utility/login-dialog/login-dialog.component';
import { Dialog } from '@angular/cdk/dialog';
import { MatDialog } from '@angular/material/dialog';
import { Utility } from 'src/app/utility/Utility';
 
const  AUTH_PATH=GlobalConstants.serverUrl
 
@Component({
  selector: 'app-user-page',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.css']
})
export class UserPageComponent implements OnInit {

  
  uid:number
  user
   posts: Observable<any>;
 
   @ViewChild(MatPaginator) paginator: MatPaginator;
 
  
  
  dataSource: MatTableDataSource<Post> = new MatTableDataSource<Post>();

  constructor(public  postService:PostService,
              public  userService:UserService,
              public  route:Router,
              public  router:ActivatedRoute,
              public snackBar: MatSnackBar,
              public dialog: MatDialog,
              private changeDetectorRef: ChangeDetectorRef,
              private  tokenStorage:TokenStorageService) { }

  ngOnInit(): void {
    this.router.queryParams.subscribe(
      r=>{      this.uid=r.uid      },
      e=> {  this.route.navigate(['/error']) }  )
     
  
      this.getSource()

   

    }
  async getSource(){
    const  u= await   this.userService.getUserById(this.uid).toPromise()
    if(u){
      console.log("User front page ==> "+JSON.stringify(u));
      
      this.user=u
    }   else   this.route.navigate(['/error'])

    const  p=await  this.postService.getallPostByUser(this.uid).toPromise()
     if(p){

      this.dataSource.data=p 
      this.dataSource.paginator = this.paginator
      this.posts= this.dataSource.connect()
      this.changeDetectorRef.detectChanges();
     }      
    else   this.route.navigate(['/error'])

    // console.log("posts =>"+JSON.stringify(u))

  }

  getImageUrl(post:Post){
    if(post.postImage.length<1){
      return "assets/img/avatar.png"
    }else {
      return   Utility.getFileURL(post.postImage[0].name)
    }

  }
  getUserImageUrl(img:String){
    if(img===undefined || img === null){
      return "assets/default_user.png"
    }else {
      return    Utility.getFileURL(img)
    }

  }
  displayPrice(post:Post){
     return   Utility.displayPrice(post.post_payment)
 }
 ngOnDestroy() {
  if (this.dataSource) { 
    this.dataSource.disconnect(); 
  }
}    
logInDialog() {
  const dialogRef = this.dialog.open(LoginDialogComponent, {
    maxWidth: "350px"

  });

  dialogRef.afterClosed().subscribe(dialogResult => {
  
     
  });

}
isUserLogIn() {
  return this.tokenStorage.isUserLoggedIn()
}
viewPhoneNumber() {
  console.log("View Phone Numer is clicked")
  if (this.tokenStorage.isUserLoggedIn()){
    //  this.abuseMessageDialog()
  }
else
    this.logInDialog()
}

}
