import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { } from '@angular/forms';
import { LocationService } from 'src/app/service/location/Sales_location.service';
import { CurrencyService } from 'src/app/service/currency/currency.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CatagoyrService } from 'src/app/service/catagory/catagory.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductSubCatagory } from 'src/app/modules/ProductSubCatagory';
import { } from '@angular/core';
import { TokenStorageService } from 'src/app/service/tokenStorage/token-storage.service';
import { PostPayment } from 'src/app/modules/PostPayment';
import { Post_status } from 'src/app/modules/Post_status';
import { UserPostService } from 'src/app/service/post/User_post.service';
import { PostRecipt } from 'src/app/modules/PostRecipt';
import { ProductCatagory } from 'src/app/modules/ProductCatagory';
import { SubcatagoryService } from 'src/app/service/subcatagory/subcatagory.service';
import { PostSave } from 'src/app/modules/post/PostSave';
import { PostSpecificationSummary } from 'src/app/modules/post/PostSpecificationSummary';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/service/common/common.service';

import { NgxImageCompressService } from 'ngx-image-compress';
import { DataUrl, UploadResponse } from 'ngx-image-compress/public-api';
import { PictureUpload } from 'src/app/modules/PictureUpload';
import { EditPostYesNoDialogComponent } from '../editpost/edit-post-yes-no-dialog/edit-post-yes-no-dialog.component';
import { GlobalConstants } from 'src/app/utility/global-constants';
import { MessageDialogComponent } from 'src/app/utility/message-dialog/message-dialog.component';
import { MessageDialogSerice } from 'src/app/service/messageDialog/MessageDialog.service';
import { LoadingService } from 'src/app/service/LodingService/loding-service.service';
import { Utility } from 'src/app/utility/Utility';

const AUTH_PATH = GlobalConstants.serverUrl

@Component({
  selector: 'app-post-new',
  templateUrl: './post-new.component.html',
  styleUrls: ['./post-new.component.css'],
})
export class PostNewComponent implements OnInit {
  form: FormGroup;
  payLoad = '';

  // old code
  @Input() chooseLabel = 'Add Image';
  @Input() deleteButtonLabel;
  @Input() deleteButtonIcon = 'close';




  public salesLocation;
  public currency1;
  public selectSubCat: ProductSubCatagory;
  public isSubCatSelected: boolean = false;
  public maximum_number_img: number;

  postForm;
  paymentform;
  packageform;

  //
  postsave: PostSave
  paymentObj: PostPayment
  serviceFee: PostRecipt
  postSpecifcation = [];
  startPosting: boolean = false;
  //
  price_readonly = true
  price_required = false
  negotiable_readonly = true
  min_readonly = true
  min_required = false
  max_readonly = true
  max_required = false

  image_old = []
  public catagories: ProductCatagory[];
  //new upload variables
  imgResultAfterCompress: DataUrl[] = [];

  imgResultMultiple: UploadResponse[] = [];
  imgResultCount: number = 0

  showProgressBar: boolean = false

  //end select catagories
  async submitPost() {

    if (this.image_old.length < this.selectSubCat.img_min) {
      this.openSnackBar('upload atleast ' + this.selectSubCat.img_min + ' images', 'Error');
    } else {


      this.formToObject()  // preppare post
      // console.log('Post data For send =>' + JSON.stringify(this.postsave));
      const p = await this.postService
        .savePost(this.postsave)
        .toPromise();

      if (p) {

        console.log("Upload post ads complete ");
        this.router.navigate(['user/post'])
          .then(() => {

            window.location.reload();
          });


      } else {


        this.router.navigate(['user/post'])
          .then(() => {

            window.location.reload();
          });
      }



    }
  }

  refreshUploadedImageView() {

    this.postService.getAllPostTempImage().subscribe(
      r => {
        this.image_old = []
        this.image_old = r
        this.showProgressBar = false
        //  console.log("list of images in post ==>"+JSON.stringify(this.image_old))

      }, e => {
        this.showProgressBar = false
        //  console.log("getAllPostTempImate()==> "+JSON.stringify(e))
      }
    )
  }
  constructor(
     public dialog: MatDialog,
    private snackbar: MatSnackBar,
    private commonService: CommonService,
    private catServive: CatagoyrService,
    private salesLocationService: LocationService,
    private subcatService: SubcatagoryService,
    private currencyService: CurrencyService,
    private sanitizer: DomSanitizer,
    private tockenStorage: TokenStorageService,
    private postService: UserPostService,
    private router: Router,
    private loadingService: LoadingService,
    private messageDialogService: MessageDialogSerice,
    private imageCompress: NgxImageCompressService
  ) { }

  ngOnInit() {
    this.postsave = new PostSave(-1, null, "", "", null, null, null, null, null, null,null)
    this.paymentObj = new PostPayment(-1, "PRICE", false, 0, null, 0, 0, null)


    this.isSubCatSelected = false;
    // load the initial catagory list


    this.form = new FormGroup({}); // = this.qcs.toFormGroup(this.questions);
    this.paymentform = new FormGroup({
      payment_option: new FormControl(''),
      negotiable: new FormControl(false),
      price_amount: new FormControl(),
      price_currency: new FormControl(),
      min: new FormControl(''),
      max: new FormControl(''),
      range_currency: new FormControl(),
    });
    this.postForm = new FormGroup({
      id: new FormControl(''),
      description: new FormControl(''),
      productSubCatagory: new FormControl(),
      detail: new FormControl(''),
      remark: new FormControl(''),
      inputFileName: new FormControl(''),
      salesLocation: new FormControl(''),
      // specification: this.form,
      post_payment: this.paymentform,
      package_fee: new FormControl("")
    });

    this.getSourceData();
  }

  async getSourceData() {
     this.loadingService.show()
    const u = await this.commonService.isUserInfoComplete().toPromise();
    if (u != null) {
      this.startPosting = u
      this.loadingService.hide()

    }

    const c = await this.catServive.getallProductCatagory().toPromise();
    if (c != null) {
      // this.catagorys = c;
      this.catagories = c
     //console.log('  catagory list ==> ' + JSON.stringify(this.catagories));
    }
    else console.log('  catagorys retrieve failed!!! ');
    //  console.log('  catagory data=>'+JSON.stringify(this.catagorys));
    const s = await this.salesLocationService.getallSalesLocation().toPromise();

    if (c != null) this.salesLocation = s;
    else console.log('  Sales Location retrieve failed!!! ');

    const cur = await this.currencyService.getallCurrency().toPromise()
    if (cur != null) this.currency1 = cur
    else console.log("  currency retrieve failed!!! ")

    const img = await this.postService.getAllPostTempImage().toPromise()
    if (img != null) this.image_old = img
    else console.log("  image retrieve failed !!! ")

      ;
  }


  async uploadMultipleFiles() {


 

    if (this.image_old.length >= this.selectSubCat.img_max) {
      this.messageDialogService.messageDialog("You cannot upload More Than " + this.selectSubCat.img_max + " images", "Error")
      return
    }

    this.imageCompress
      .uploadMultipleFiles()
      .then(async (multipleOrientedFiles: UploadResponse[]) => {
        this.imgResultMultiple = multipleOrientedFiles;
        //==================
        //setting variables
        //===================
        var image_old_count = this.image_old.length  // image count in display
        var required_extra_image_count = this.selectSubCat.img_max - image_old_count // number of images i can add
        var loop_number = 0   //how much i can add

        if (this.imgResultMultiple.length > required_extra_image_count) {  // if i have more than required image
          loop_number = required_extra_image_count
        } else {                      // if i have less image than required
          loop_number = this.imgResultMultiple.length
        }
        //==================
        //setting variables
        //===================
        

        for (let index = 0; index < loop_number; index++) {

       
          const imgwidth = await this.getImageWidth(this.imgResultMultiple[index].image)
          var size = this.getImageSize(this.imgResultMultiple[index].image)

          if (this.imgResultAfterCompress.length < this.selectSubCat.img_max) { //check number of image 
            if (imgwidth > 800) {

              if (size > 0.5 && size < 5) {
                var ratio = (Number)((0.8 * 100) / size)
             
                this.imageCompress
                  .compressFile(this.imgResultMultiple[index].image, this.imgResultMultiple[index].orientation, ratio, 80)
                  .then((result: DataUrl) => {
                   
                    this.imgResultAfterCompress[index] = result;

                  }).then(() => {
                    this.postService.saveTemporaryUploadImages(this.imgResultAfterCompress[index]).subscribe(
                      r => {
                         
                        this.refreshUploadedImageView()

                      }, e => {
                        // this.router.navigate(['user/post'])
                        // .then(() => {
                        //      
                        //   window.location.reload();
                        // })
                      }
                    )

                  });
              } else {
                if (size >= 5) {
                  this.messageDialogService.messageDialog("max image size 5MB", "Message");
                }
              } if (size <= 0.5) {
                // console.log("Image No need for compression ");

                this.imgResultAfterCompress[index] = this.imgResultMultiple[index].image
                this.uploadFilesOneByOne(index)
              }
            } else {
              this.messageDialogService.messageDialog("min Image width 800px ==>" + imgwidth, "Message");
              // console.log("min Image width 800px ==>" + imgwidth);

            }
          } else {
            this.messageDialogService.messageDialog("Maximum " + this.selectSubCat.img_max + " images are allowed", "Message");
            //console.log("Maximum " + this.selectSubCat.img_max + " images are allowed");

          }



        }


      });




  }
  async uploadFilesOneByOne(index:number) {
 
    
    this.loadingService.show()
    // console.log("steps for our new procedure");
       this.postService.saveTemporaryUploadImages(this.imgResultAfterCompress[index]).subscribe(
          r => {
          // console.log(index + "<=1.1=>   this.image_old.length+ index+1 => " + (this.image_old.length + index + 1))
            this.refreshUploadedImageView()
             this.loadingService.hide()
         }, e => {
            // this.router.navigate(['user/post'])
            // .then(() => {
            //      
            //   window.location.reload();
            // })
           this.loadingService.hide()
          }
        )
     
    




  }
  getImageWidth(file: any) {

    return new Promise<number>((resolve, reject) => {
      const img = new Image();
      img.src = file as string;
      img.onload = () => {
        const height = img.naturalHeight;
        const width = img.naturalWidth;
        resolve(width)
      };

    })



  }
  getImageSize(file: any) {
    var size = this.imageCompress.byteCount(file);
    return (Number)(size / (1024 * 1024))

  }

  // removeFile(event, result: any) {
  //   console.log("removeFile ==>" + JSON.stringify(result))
  //   this.imgResultAfterCompress.splice(result, 1);
  // }


  isMultiple(): boolean {
    return true;
  }


  get productSubCatagory() {
    return this.postForm.get('productSubCatagory');
  }
  get description() {
    return this.postForm.get('description');
  }
  // get specification() {
  //   return this.postForm.get('specification')
  // }
  get detail() {
    return this.postForm.get('detail');
  }
  get remark() {
    return this.postForm.get('remark');
  }
  // get price() {
  //   return this.postForm.get('price')
  // }
  // get currency() {
  //   return this.postForm.get('currency')
  // }
  get inputFileName1() {
    return this.postForm.get('inputFileName');
  }
  openSnackBar(message, type) {
    this.snackbar.open(message, type, {
      duration: 2000,
    });
  }
  async onCatagoryChange(ev) { //changed

    // console.log(' on catagory changee = >' + JSON.stringify(ev.value.id));

    if (ev.value.id) {
      const s = await this.subcatService.getProductSubCatagoryById(ev.value.id).toPromise()
      if (s) {
        this.selectSubCat = s
        // console.log('form = >' + JSON.stringify(this.selectSubCat));

        // update  the form
        let group = {};
        this.selectSubCat.specificationList.sort((a, b) => (a.id > b.id) ? 1 : -1)

        this.selectSubCat.specificationList.forEach((specification) => {
          // group[specification.key] = new FormControl('');
          group[specification.key] = specification.required ? new FormControl('', Validators.required)
            : new FormControl('');

        });

        this.postForm.removeControl('specification')
        this.postForm.addControl('specification', new FormGroup(group))

        this.postForm.patchValue({
          post_payment: {
            negotiable: false,
            price_amount: "",
            min: "",
            max: "",
            price_currency: "",
            range_currency: ""
          }
        })
        // end of  update form specification list
        this.isSubCatSelected = true; // make catagory slected true
      } else {
        this.isSubCatSelected = false;
      }

    }
    else this.isSubCatSelected = false;

  }
  payment_required() {
    if (this.selectSubCat.commision === false &&
      this.selectSubCat.contact === false &&
      this.selectSubCat.price === false &&
      this.selectSubCat.range === false) {
      return false
    } else return true
  }
  fee_required() {
    if (this.selectSubCat.fee_free_enable === false &&
      this.selectSubCat.fee_week_enable === false &&
      this.selectSubCat.fee_month_enable === false &&
      this.selectSubCat.fee_year_enable === false) {
      return false
    } else return true

  }
  public formToObject() {


    this.postsave.productSubCatagory = this.selectSubCat.id
    this.postsave.description = this.postForm.value.description
    this.postsave.salesLocation = this.postForm.value.salesLocation.id
    this.postsave.detail = this.postForm.value.detail
    this.postsave.remark = this.postForm.value.remark
    this.postsave.post_status = this.getPostStatus()
    this.postsave.user = this.tockenStorage.getUserId()

    // payment option  
    this.postsave.post_payment = this.getPostPayment()

    this.postsave.specificationList = this.getPostSpecification()
    this.postsave.serviceOption = this.getServiceFee()

    return this.postsave;
  }

  paymentOnChange() {
    //  console.log("radio button changed to ==>"+JSON.stringify(this.postForm.value.post_payment))

    if (this.postForm.value.post_payment.payment_option === "PRICE") {
      this.price_readonly = false
      this.price_required = true
      this.negotiable_readonly = false
      this.min_readonly = true
      this.min_required = false
      this.max_readonly = true
      this.max_required = false
      this.postForm.patchValue({
        post_payment: {
          // negotiable:false,
          // price_amount:"",
          min: "",
          max: ""
        }
      })

    } else if (this.postForm.value.post_payment.payment_option === "RANGE") {
      this.postForm.patchValue({
        post_payment: {
          negotiable: false,
          price_amount: "",
          // min:"",
          // max:""
        }
      })

      this.price_readonly = true
      this.price_required = false
      this.negotiable_readonly = true
      this.min_readonly = false
      this.min_required = true
      this.max_readonly = false
      this.max_required = true
    } else {
      this.postForm.patchValue({
        post_payment: {
          negotiable: false,
          price_amount: "",
          min: "",
          max: "",
          price_currency: "",
        }
      })

      this.price_readonly = true
      this.price_required = false
      this.negotiable_readonly = true
      this.min_readonly = true
      this.min_required = false
      this.max_readonly = true
      this.max_required = false
    }
  }

  getPostPayment() {

    this.paymentObj.option = this.postForm.value.post_payment.payment_option
    this.paymentObj.price_amount = this.postForm.value.post_payment.price_amount
    this.paymentObj.min = this.postForm.value.post_payment.min
    this.paymentObj.max = this.postForm.value.post_payment.max
    if (this.paymentObj.option != "PRICE") {
      this.paymentObj.price_currency = null
    } else this.paymentObj.price_currency = this.postForm.value.post_payment.price_currency
    this.paymentObj.negotiable = this.postForm.value.post_payment.negotiable
    if (this.paymentObj.option != "RANGE") {
      this.paymentObj.range_currency = null
    } else this.paymentObj.range_currency = this.postForm.value.post_payment.range_currency


    return this.paymentObj
  }
  getServiceFee() {

    var index = this.postForm.value.package_fee
    if (index == "1") {
      return 'FREE'

    }
    else if (index == "2") {
      return 'WEEK'

    }
    else if (index == "3") {
      return 'MONTH'

    }
    else if (index == "4") {
      return 'YEAR'

    }




  }

  getPostSpecification() {
    this.postSpecifcation.splice(0, this.postSpecifcation.length)
    //  console.log("length of array =>" + this.selectSubCat.specificationList.length)
    for (let index = 0; index < this.selectSubCat.specificationList.length; index++) {

      // console.log("index = "+index+" data => "+ JSON.stringify(
      // this.postForm.value.specification[this.selectSubCat.specificationList[index].key]
      // ))
      //  console.log("value for send ==> "+
      //  JSON.stringify(this.postForm.value.specification[this.selectSubCat.specificationList[index].key]));


      this.postSpecifcation.push(new PostSpecificationSummary(null,
        this.selectSubCat.specificationList[index].id,
        this.postForm.value.specification[this.selectSubCat.specificationList[index].key]))

    }
    return this.postSpecifcation

  }
  getPostStatus() {
    return new Post_status(-1, "PENDING", null, null, null, null, null, null, null)
  }
  onOpenChange(comp: any) {
    // console.log("onOpenChange value" + JSON.stringify(comp))


  //  console.log(" value of == > " + JSON.stringify(this.postForm.get('specification').get(comp).value));
    comp.options = [{ "id": 15181, "item": "Lemans", "pvalue": "" },
    { "id": 15182, "item": "GTO", "pvalue": "" }]
    // this.postForm.get('specification').get(comp).patchValue({
    //   options:  [{"id":15181,"item":"Lemans","pvalue":""},
    //                {"id":15182,"item":"GTO","pvalue":""}]
    // })


  }
  changeParent(e: any, comp: any) {
    this.selectSubCat.specificationList.forEach(x => {
      if (x.parentkey === comp) {
        x.parentvalue = e
        this.postForm.get("specification").get(x.key).setValue("")
      }

    });
    //  var i=   this.regConfig.findIndex(x=>x.parentkey===comp)
    //   this.regConfig[i]
    //  console.log("index value ==>"+JSON.stringify(i));
    // console.log("e value ==>" + JSON.stringify(e));
    // console.log("c value ==>" + JSON.stringify(comp));
  }



  protected copyCatagoryGroups(catagories: ProductCatagory[]) {
    const catagoriesCopy = [];
    catagories.forEach(catagory => {
      catagoriesCopy.push({
        id: catagory.id,
        name: catagory.name,
        img: catagory.img,
        productSubCatagory: catagory.productSubCatagory.slice()
      });
    });
    return catagoriesCopy;
  }
  //end of select search catagory

  deleteOldImage(name) {

    if (this.deleteDialog(name)) {
      // console.log("success of delit ")
    }

  }
  async deleteDialog(image: PictureUpload) {
    const dialogRef = this.dialog.open(EditPostYesNoDialogComponent, {
      width: '300px',
      data: image,
    });

    dialogRef.afterClosed().subscribe(result => {

      this.deletePostImageAction(result)


    }, error => {

      this.openSnackBar("Image Deleted fail!!!", "Error")
    }

    );
  }

  async deletePostImageAction(image: PictureUpload) {

    const i = await this.postService.deleteTempPostImagesByName(image.name).toPromise()
    // console.log("i ==>" + JSON.stringify(i))
    if (i) {
      const y = await this.postService.getAllPostTempImage().toPromise()
      // console.log("y ==>" + JSON.stringify(y))
      if (y) this.image_old = y

      this.openSnackBar("Image Deleted !!!", "Message")
    }
  }
  getImageUrl(img) {

    return Utility.getFileURL( img + "?");//+(new Date()).getTime();

  }
}
